<template>
  <div class="auth bg-primary--light h-100 d-flex align-items-center">
    <b-container class="h-100">
      <b-row class="h-100" align-h="center" align-v="center">
        <b-col cols="8">
          <router-view />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.auth {
  min-height: 100vh;
  .logo {
    padding: 6vw 0 6vw;
    img {
      width: 14rem;
    }
  }
  .caption {
    height: 100vh;
    @media screen and (max-width: 768px) {
      height: 100%;
      text-align: center;
      h1 {
        margin: 0 auto;
        width: 70% !important;
        margin-bottom: 3rem;
      }
    }
    h1 {
      width: 90%;
      font-weight: 900;
      color: #02123b;
      font-family: var(--primary-font);

      @media screen and (max-width: 500px) {
        font-size: 1.4rem;
        width: 85% !important;
        margin-bottom: 3rem;
      }
    }
  }

  .auth-card {
    width: 70%;
    // height: 60vh;
    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }

  .suggested {
    a {
      color: #02123b;
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }
}
</style>
